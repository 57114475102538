import classNames from 'classnames';
import { authContext, useUserAccessData } from 'contexts/AuthContext';
import { useUserMarkets } from 'hooks/useUserMarkets';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import locations from 'routes';
import MarketDropdown from './Components/MarketDropdown';
import MenuDropdown from './Components/MenuDropdown';
import styles from './SubNavBar.module.scss';
import {
  getCompPropertiesLinks,
  getMappingLinks,
  getMarketAnalyticsLinks,
} from './utils';
import { translateText } from 'utils/i18n';
import {
  PROPERTY_CLASSES_QUERY,
  PROPERTY_TYPES_ENTITIES_QUERY,
} from 'graphql/property';
import { IPropertyType } from 'interfaces/IPropertyType';
import { useSubtypes } from 'components/CreateComps/FormSection/PropertyForm/usePropertyForm';
import { IPropertySubType } from 'interfaces/IPropertySubType';
import { IPropertyClasses } from 'interfaces/IPropertyClasses';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { DYNAMIC_URLS } from 'graphql/dynamicUrl';
import { IDynamicURL } from 'interfaces/IDynamicURL';
import { DynamicUrlCategory } from 'constants/dynamicUrlCategory';
import { PropertyTypeNames } from 'constants/propertyTypes';

const ALLOWED_PROPERTIES = ['Trophy', 'A', 'B', 'C'];
const ALLOWED_SUBTYPES = ['Industrial', 'Flex Industrial'];

const SubNavBar = () => {
  const { user } = useContext(authContext);
  const history = useHistory();
  const { fullAccess, accessData } = useUserAccessData();
  const { userMarkets } = useUserMarkets(user, true);
  const [
    userPreferredType,
    setUserPreferredType,
  ] = useState<IPropertyType | null>(null);

  const [userPreferredSubType, setUserPreferredSubType] = useState<
    IPropertySubType[] | null
  >(null);

  const [propertyClasses, setPropertyClasses] = useState<IPropertyClasses[]>(
    [],
  );

  const [queryClasses, { data: classesData }] = useLazyQuery<{
    propertyClasses: IPropertyClasses[];
  }>(PROPERTY_CLASSES_QUERY);

  const buildPropertyClasses = useCallback(() => {
    if (!classesData?.propertyClasses) return;
    const possibleClasses: IPropertyClasses[] = [];

    classesData?.propertyClasses.forEach(classes => {
      if (!ALLOWED_PROPERTIES.includes(classes.name)) return;
      const newClass = { ...classes };

      if (classes.name !== 'Trophy') {
        newClass['name'] = `Class ${classes['name']}`;
      }

      possibleClasses.push(newClass);
    });

    setPropertyClasses(possibleClasses);
  }, [classesData]);

  useEffect(() => {
    buildPropertyClasses();
  }, [buildPropertyClasses]);

  const { get: querySubTypes, data: subTypesData } = useSubtypes();

  // TODO: discuss with team to see the possiblity of doing this task without another API call
  const { data: userPropertyTypes } = useQuery<{
    propertyTypesEntities: IPropertyType[];
  }>(PROPERTY_TYPES_ENTITIES_QUERY);

  const getPropertiesDeepLink = React.useCallback(() => {
    if (!subTypesData || !userPreferredType?.name) return;

    if (!ALLOWED_SUBTYPES.includes(userPreferredType?.name)) {
      return queryClasses();
    }

    const subTypes = subTypesData?.propertySubtypes.filter(subType => {
      if (subType.name === 'Film Studio') return;
      const transformedType = userPreferredType?.name
        ?.replaceAll(' ', '')
        .toUpperCase();
      return subType.type === transformedType;
    });

    setUserPreferredSubType(subTypes);
  }, [queryClasses, subTypesData, userPreferredType]);

  const getUserPreferredType = React.useCallback(() => {
    const userPreferredType = userPropertyTypes?.propertyTypesEntities?.find(
      type =>
        type.id &&
        user.preferences &&
        user.preferences.propertyType &&
        PropertyTypeNames[type.id] === user.preferences.propertyType,
    );

    if (!userPreferredType?.id) return;

    querySubTypes();

    return setUserPreferredType(userPreferredType);
  }, [querySubTypes, user, userPropertyTypes]);

  React.useEffect(() => {
    getUserPreferredType();
  }, [getUserPreferredType]);

  React.useEffect(() => {
    getPropertiesDeepLink();
  }, [getPropertiesDeepLink]);

  const analyticsDashboardsId = accessData?.analyticsDashboardsId || [];

  const translationPath = `avantPlatform.common.words`;

  const { data: dynamicUrlsData, loading: isLoadingDynamicUrls } = useQuery<{
    dynamicURLs: IDynamicURL[];
  }>(DYNAMIC_URLS, {
    variables: {
      search: {
        categories: DynamicUrlCategory.ANALYTICSDASHBOARDS,
      },
    },
  });

  return (
    <div className={styles['menu-container']}>
      <div className={classNames(styles['menu-item'], styles['activity'])}>
        <Link to={locations.exploreActivity()} className={styles['menu-link']}>
          {translateText(`${translationPath}.news`)}
        </Link>
      </div>
      <div className={styles['menu-item']}>
        <MarketDropdown
          id="subnav-market-overview"
          label={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.market_plural`,
          )}
        />
      </div>
      <div className={styles['menu-item']}>
        <MenuDropdown
          id="subnav-comps-dropdown"
          label={translateText(`${translationPath}.data`)}
          listItems={getCompPropertiesLinks(
            user,
            userMarkets,
            userPreferredType,
            userPreferredSubType,
            propertyClasses,
            history,
          )}
        />
      </div>
      {!!analyticsDashboardsId && (
        <div className={styles['menu-item']}>
          <MenuDropdown
            id="subnav-market-dropdown"
            label={translateText(`${translationPath}.analytics`)}
            listItems={getMarketAnalyticsLinks(
              history,
              dynamicUrlsData?.dynamicURLs,
              analyticsDashboardsId,
              fullAccess,
            )}
            isLoading={isLoadingDynamicUrls}
            gridClassName={styles['market-analytics-dropdown']}
          />
        </div>
      )}
      <div className={styles['menu-item']}>
        <MenuDropdown
          id="subnav-mapping"
          label={translateText(`${translationPath}.avantCities`)}
          listItems={getMappingLinks()}
          gridClassName={styles['mapping-dropdown']}
        />
      </div>
    </div>
  );
};

export default SubNavBar;
