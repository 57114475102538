import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Icon from 'components/Icon';
import Truncate from 'components/Truncate';
import { useUser } from 'contexts/AuthContext';
import { colors } from 'constants/colors';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import { isUserUsingEnglishLanguage } from 'utils/user';
import styles from './TextInput.module.scss';

export interface Props {
  borderBox?: boolean;
  formatter?: (value: string, isFocused?: boolean) => string;
  fullWidth?: boolean;
  iconPosition?: 'left' | 'right';
  isDisabled?: boolean;
  id?: string;
  isLarge?: boolean;
  isNarrow?: boolean;
  isSmall?: boolean;
  label?: string;
  onChange?: (value: string) => void;
  onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  textCentered?: boolean;
  value?: string | number | null;
  wrapperClassName?: string;
  onClear?: () => void;
  onBlur?: (value: string) => void;
  isInvalid?: boolean;
  isPlaceholderCustom?: boolean;
  pattern?: RegExp;
  isRequired?: boolean;
  autoFocus?: boolean;
  skipAutoFormat?: boolean;
  inputClassName?: string;
  labelClassName?: string;
  disabledPlaceholder?: string;
  setCurrentFocusedFieldId?: (id: string) => void;
  startNode?: React.ReactNode;
}

const TextInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    autoFocus,
    borderBox,
    formatter,
    fullWidth,
    iconPosition,
    isDisabled,
    id,
    isLarge,
    isNarrow,
    isSmall,
    label,
    onChange,
    onInput,
    placeholder,
    readOnly,
    textCentered,
    value,
    wrapperClassName,
    onClear,
    onBlur,
    isInvalid,
    pattern,
    isRequired,
    skipAutoFormat,
    inputClassName,
    labelClassName,
    isPlaceholderCustom,
    disabledPlaceholder = DASH_PLACEHOLDER,
    setCurrentFocusedFieldId,
    startNode,
  } = props;
  const { user } = useUser();
  const { t } = useTranslation();
  const [lastMatch, setLastMatch] = useState('');
  const [isFocused, setIsFocused] = useState<boolean>(!!autoFocus);

  const getValue = () => {
    if (value && formatter) return formatter(`${value}`, isFocused);
    if (value === null || value === undefined) return '';
    return value;
  };

  const renderInput = () => {
    return (
      <input
        ref={ref}
        id={id}
        autoFocus={autoFocus}
        data-testid={id}
        type="text"
        placeholder={isDisabled ? disabledPlaceholder : placeholder}
        readOnly={readOnly}
        className={classnames(
          styles.input,
          {
            [styles[`input-clear-padding`]]: onClear && value,
            [styles[`input-icon-${iconPosition}`]]: iconPosition,
            [styles['input-full-width']]: fullWidth,
            [styles['input-large']]: isLarge,
            [styles['input-small']]: isSmall,
            [styles['input-border-box']]: borderBox,
            [styles['input-narrow']]: isNarrow,
            [styles['input-text-centered']]: textCentered,
            [styles['input-text-invalid']]: isInvalid,
            [styles['input-custom']]: isPlaceholderCustom,
            [styles['focus']]: isFocused,
            [styles['error']]: isInvalid,
          },
          inputClassName,
        )}
        value={getValue()}
        onChange={e => {
          const { value: val } = e.target;

          const hasPatternAndMatchesIt = pattern && !!val.match(pattern);

          if (skipAutoFormat || !val || !pattern || hasPatternAndMatchesIt) {
            if (hasPatternAndMatchesIt) {
              setLastMatch(val);
            }
            onChange?.(val);
          }
        }}
        onBlur={e => {
          setIsFocused(false);
          setCurrentFocusedFieldId?.('');
          onBlur?.(e.target.value);
          const val = e.target.value;
          const hasPatternAndMatchesIt = pattern && val.match(pattern);

          if (val && pattern && !hasPatternAndMatchesIt) {
            onChange?.(lastMatch);
          }
        }}
        onFocus={() => {
          setIsFocused(true);
          setCurrentFocusedFieldId?.(id ?? '');
        }}
        disabled={isDisabled}
        onInput={e => onInput?.(e)}
      />
    );
  };

  const renderClearButton = () => {
    if (onClear && value) {
      return (
        <button onClick={onClear} className={styles['clear-button']}>
          <Icon name="clear" size={0.625} color={colors.ayGrey40Color} />
        </button>
      );
    }
    return null;
  };

  const renderLabel = () =>
    label && (
      <label
        className={classnames(
          styles.label,
          {
            [styles['label-required']]: isRequired,
          },
          labelClassName,
        )}
        htmlFor={id}
      >
        {isUserUsingEnglishLanguage(user) ? (
          label
        ) : (
          <Truncate length={29} wrapperClassName={styles.truncate}>
            {label}
          </Truncate>
        )}
        {isRequired && (
          <span role="note">
            {' '}
            {`(${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.required`)})`}
          </span>
        )}
      </label>
    );

  return (
    <div className={classnames(styles.container, wrapperClassName)}>
      {renderLabel()}
      {startNode ? (
        <div className={styles['input-node']}>
          {startNode}
          {renderInput()}
        </div>
      ) : (
        renderInput()
      )}

      {renderClearButton()}
    </div>
  );
});

export default TextInput;
