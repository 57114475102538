import React, { useEffect, useRef, useState } from 'react';
import MapboxMap from 'components/MapboxMap';
import MapLoader from 'components/MapboxMap/MapLoader';
import styles from './MarketsMap.module.scss';
import { Col, Row } from 'components/@codelitt/ay-design-library';
import {
  getMapCenterLatLong,
  removeLayerSourceFromMap,
  setPropertiesPinsVisibility,
} from 'utils/maps/mapBox';
import {
  generateHeatMapData,
  ICoords,
  setHeatmapLayerVisibility,
} from 'components/MapboxMap/utils';
import classnames from 'classnames';
import useHeatmapControls from 'components/MapboxMap/useMapboxOptions';
import { DEFAULT_ZOOM } from 'components/PropertiesMap/constants';
import { CompositionSectionProps } from 'components/MarketProfile/constants';
import { useMarketData } from './useMarketData';
import { loadPropertiesOnMap } from 'components/SubmarketProfile/Sections/Composition/PropertiesMap/SubmarketsMap/utils';
import {
  MARKET_BORDER,
  MARKET_LAYER,
  MARKET_BOUNDARIES,
  MIN_TIMEOUT,
} from './constants';
import { loadMarketBoundariesOnMap } from './utils';
import { useTranslation } from 'react-i18next';
import avantCitiesRoutes from 'avantCitiesRoutes';
import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';
import { PropertyTypeNames } from 'constants/propertyTypes';
import { HeatMap, VisibilityLayer } from 'components/MapboxMap/constants';

export interface MarketsMapProps extends CompositionSectionProps {
  isSmallSize?: boolean;
  styleSize?: string;
  mapZoom?: number;
  shouldShowOnlySelectedBoundaries?: boolean;
  showHeatMapControls?: boolean;
  showMapControls?: boolean;
  showOpenMap?: boolean;
}

const MarketMap: React.FC<MarketsMapProps> = ({
  activeOption,
  isSmallSize = false,
  styleSize,
  mapZoom = DEFAULT_ZOOM,
  propertyType,
  setPropertiesPopup,
  shouldShowOnlySelectedBoundaries = false,
  showHeatMapControls = false,
  showMapControls = true,
  showOpenMap = false,
  market,
  trackedProperties,
}) => {
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const [isMapReady, setIsMapReady] = useState(false);
  const [mapCenterLngLat, setMapCenterLngLat] = useState<ICoords>();
  const { heatmapState, setHeatmapState } = useHeatmapControls({
    heatmapSelectedOption: HeatMap.SEARCH_RESULTS,
    isHeatmapControlsOpen: false,
  });
  const { t } = useTranslation();

  const { boundariesData, isLoadingBoundaries, properties } = useMarketData(
    {
      market,
      activeOption,
      propertyType,
      setPropertiesPopup,
      trackedProperties,
    },
    shouldShowOnlySelectedBoundaries,
  );

  const handleLoad = async (map: mapboxgl.Map) => {
    setIsMapReady(false);
    mapRef.current = map;
    map.on('load', () => setIsMapReady(true));
  };

  const handleOnClickPropertyPin = (propertyId: string | null) =>
    !!propertyId &&
    setPropertiesPopup?.({
      hideAction: true,
      isVisible: true,
      popupFilter: { ids: [Number(propertyId)] },
    });

  useEffect(() => {
    const timeout = setTimeout(() => {
      const isReadyToDrawBoundaries =
        isMapReady && boundariesData?.submarketsBoundaries;
      const isReadyToDrawPins = isMapReady && !!properties;

      const selectedSubmarket = boundariesData?.submarketsBoundaries?.[0];

      if (selectedSubmarket?.geometry?.coordinates) {
        const centerLatLng = getMapCenterLatLong(selectedSubmarket?.geometry);
        setMapCenterLngLat({
          latitude: centerLatLng.lat,
          longitude: centerLatLng.lng,
        });
      }

      if (
        !isReadyToDrawBoundaries ||
        (!isReadyToDrawPins && !shouldShowOnlySelectedBoundaries) ||
        !mapCenterLngLat
      )
        return;

      loadMarketBoundariesOnMap(
        mapRef.current!,
        boundariesData?.submarketsBoundaries,
      );

      if (!shouldShowOnlySelectedBoundaries && !!properties?.length) {
        loadPropertiesOnMap(
          mapRef.current!,
          properties,
          handleOnClickPropertyPin,
        );
      }

      if (showHeatMapControls && properties?.length) {
        generateHeatMapData(mapRef.current!, properties, isMapReady);
      }

      setHeatmapLayerVisibility(mapRef.current!, VisibilityLayer.visible);
      setPropertiesPinsVisibility(VisibilityLayer.none);
    }, MIN_TIMEOUT);

    return () => {
      timeout && clearTimeout(timeout);

      try {
        if (mapRef && mapRef.current) {
          removeLayerSourceFromMap(mapRef.current!, [
            { id: MARKET_BORDER, type: 'layer' },
            { id: MARKET_LAYER, type: 'layer' },
            { id: MARKET_BOUNDARIES, type: 'source' },
          ]);
        }
      } catch (error) {
        console.error('Error while updating market map layers:', error);
      }
    };
    // eslint-disable-next-line
  }, [
    properties,
    market,
    isMapReady,
    boundariesData,
    shouldShowOnlySelectedBoundaries,
  ]);

  const mapText = !isSmallSize
    ? t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.loadingMarket`)
    : '';

  const handleOpenMap = () => {
    const propertyTypeName = PropertyTypeNames[propertyType.id];
    const url = `explore?marketId=${market.id}&propertytype=${propertyTypeName}`;

    window.open(`${avantCitiesRoutes.root()}/${url}`, '_blank');
  };

  return (
    <Row wrapperClassName={styles.container}>
      <Col>
        <MapboxMap
          loadMap={handleLoad}
          pinIds={properties?.map(p => p.id!) || []}
          centerCoordinate={mapCenterLngLat}
          isCenterMarkerVisible={true}
          mapZoom={mapZoom}
          showControls={showMapControls}
          wrapperClassName={classnames(styles.mapbox, styleSize, {
            [styles.small]: isSmallSize,
          })}
          isAttributionHidden={isSmallSize}
          showHeatMapControls={showHeatMapControls}
          openMap={showOpenMap ? handleOpenMap : undefined}
          heatmapState={heatmapState}
          setHeatmapState={setHeatmapState}
        >
          {isLoadingBoundaries && <MapLoader text={mapText || ''} />}
        </MapboxMap>
      </Col>
    </Row>
  );
};

export default MarketMap;
