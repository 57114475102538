import FormControl from 'components/FormControl';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import UnitOfMeasurementLegend from 'components/UnitOfMeasurementLegend';
import { I18N_AVANT_PLATFORM_LEASE_PROMPT_PATH } from 'constants/i18n';
import { IProperty } from 'interfaces/IProperty';
import React from 'react';
import {
  currencyInputFormatter,
  getCurrencySymbol,
} from 'utils/formatters/currency';
import { translateText } from 'utils/i18n';
import styles from '../../FormSection.module.scss';
import { getAnnualMonthlyOptions } from '../../PropertyForm/utils';

interface Props {
  value?: number;
  required?: boolean;
  property?: IProperty;
  timeMeasurement?: string | null | undefined;
  unitOfMeasurement: string;
  onChange: (field: string, value: any) => void;
  showTimeMeasurement?: boolean;
}

const AskingRent: React.FC<Props> = ({
  value,
  property,
  onChange,
  unitOfMeasurement,
  required = false,
  timeMeasurement,
  showTimeMeasurement,
}) => {
  return (
    <FormControl
      required={required}
      wrapperClassName={styles['form-row']}
      label={translateText('avantPlatform.attributes.lease.label.askingRent')}
    >
      <div className={styles['large-input-container']}>
        <CurrencyInput
          value={value}
          id="asking-rent"
          iconPosition={'left'}
          placeholder={translateText(
            `${I18N_AVANT_PLATFORM_LEASE_PROMPT_PATH}.enterQuantity`,
          )}
          currencySymbol={getCurrencySymbol(property?.currencyCode)}
          onChange={value => onChange('askingRent', value || undefined)}
          formatter={value => currencyInputFormatter(value, true)}
        />
        <UnitOfMeasurementLegend unitOfMeasurement={unitOfMeasurement} />
        {showTimeMeasurement && (
          <ChoiceInput
            selectedItem={{
              id: timeMeasurement,
              name: timeMeasurement,
            }}
            data={getAnnualMonthlyOptions()}
            onChange={item => onChange('timeMeasurement', item?.id)}
            containerWrapperClassName={styles['radio-input-container']}
          />
        )}
      </div>
    </FormControl>
  );
};

export default AskingRent;
