import React, { CSSProperties, ReactNode, useEffect } from 'react';
import styles from './ModalWrapper.module.scss';
import Portal from '../Portal';
import IconCloseModal from 'assets/images/icons/close-modal.svg';
import { Keyboard } from 'constants/keyboard';
import classnames from 'classnames';
import { ColorNames } from 'constants/colorNames';
import { useTranslation } from 'react-i18next';
import { translateText } from 'utils/i18n';
import {
  I18N_PLATFORM_COMMON_WORD_PATH,
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
} from 'constants/i18n';

interface Props {
  allowOverflowY?: boolean;
  closeLabel?: string;
  compressed?: boolean;
  darkTheme?: boolean;
  extraLarge?: boolean;
  header?: string;
  footer?: React.ReactNode;
  headerWrapperClassName?: string;
  footerWrapperClassName?: string;
  fullScreen?: boolean;
  keepScrollLocked?: boolean;
  large?: boolean;
  modalBackgroundColor?: ColorNames;
  noPadding?: boolean;
  onCancel: () => void;
  withCloseButton?: boolean;
  withCloseLabel?: boolean;
  withOpacity?: boolean;
  wrapperClassName?: string;
  headerStyle?: CSSProperties;
  footerStyle?: CSSProperties;
  children?: ReactNode;
}

const ModalWrapper: React.FC<Props> = props => {
  const { t } = useTranslation();
  const {
    allowOverflowY,
    children,
    closeLabel = t(`${I18N_PLATFORM_COMMON_WORD_PATH}.close`),
    compressed,
    darkTheme,
    extraLarge,
    header,
    footer,
    headerWrapperClassName,
    footerWrapperClassName,
    fullScreen,
    keepScrollLocked,
    large,
    modalBackgroundColor,
    noPadding,
    onCancel,
    withCloseButton,
    withCloseLabel,
    withOpacity,
    wrapperClassName,
    headerStyle,
    footerStyle,
  } = props;

  const modalBackgroundColorClass =
    styles[`background-${modalBackgroundColor || ColorNames.ayWhiteColor}`];

  useEffect(() => {
    const keyDownEventListener = (ev: KeyboardEvent) => {
      if (ev.keyCode === Keyboard.Esc) {
        onCancel();
      }
    };

    document.addEventListener('keydown', keyDownEventListener);
    return () => document.removeEventListener('keydown', keyDownEventListener);
  });

  const getCloseButton = () =>
    withCloseButton ? (
      <div
        className={classnames(styles['close-container'], {
          [styles['close-dark-mode']]: darkTheme,
          [styles['with-header']]: header,
        })}
        onClick={onCancel}
        role="button"
        aria-label={translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.closeModal`,
        )}
      >
        {withCloseLabel && (
          <span className={styles['close-label']}>{closeLabel}</span>
        )}

        <img
          className={styles['close-icon']}
          src={IconCloseModal}
          alt={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.close`)}
        />
      </div>
    ) : null;

  return (
    <Portal keepScrollLocked={keepScrollLocked} onClick={onCancel}>
      <div className={styles.backdrop} />
      <div className={styles.container}>
        <div
          className={classnames(
            styles.modal,
            wrapperClassName,
            modalBackgroundColorClass,
            {
              [styles['compressed']]: compressed,
              [styles['modal-large']]: large,
              [styles['modal-extra-large']]: extraLarge,
              [styles['modal-full-screen']]: fullScreen,
              [styles['modal-with-opacity']]: withOpacity,
              [styles['modal-no-padding']]: noPadding,
              [styles['modal-dark']]: darkTheme,
              [styles['overflow-visible']]: allowOverflowY,
            },
          )}
        >
          {header && (
            <header
              className={classnames(styles.header, headerWrapperClassName)}
              style={headerStyle}
            >
              <h2 className={styles['header-text']}>{header}</h2>
              {getCloseButton()}
            </header>
          )}

          {!header && getCloseButton()}
          <div className={styles.content} role="dialog">
            {children}
          </div>
          {footer && (
            <div
              className={classnames(styles.footer, footerWrapperClassName)}
              style={footerStyle}
            >
              {footer}
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};

export default ModalWrapper;
