import React, { FunctionComponent, useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import styles from './PrivateRoute.module.scss';
import Navbar from 'components/Navbar';
import locations from 'routes';
import { IUser } from 'interfaces/IUser';
import classNames from 'classnames';
import { ROOT_PATH } from 'routes';
import SubNavBar from 'components/SubNavBar';
import { isSubNavBarAllowedByPathname, SUBNAVBAR_PAGES } from './utils';
import { subscriptionsContext } from 'contexts/SubscriptionsContext';
import { useUserAccessData } from 'contexts/AuthContext';
import AuthService from 'services/Auth';

interface Props {
  [routeProp: string]: any;
  user: IUser;
  component: FunctionComponent;
  feature?: string;
}

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  user,
  feature,
  ...rest
}) => {
  const location = useLocation();
  const { checkSubscriptions } = useContext(subscriptionsContext);
  const isRootLocation = location.pathname === ROOT_PATH;
  const isSubnavAllowed =
    (feature &&
      SUBNAVBAR_PAGES.includes(feature) &&
      checkSubscriptions(feature)) ||
    isSubNavBarAllowedByPathname(location.pathname);
  const { fullAccess } = useUserAccessData();

  const isProfileIncomplete =
    !user.isProfileCompleted || !user.preferences?.termsAndConditionsAccepted;

  const displaySubNavBar = isSubnavAllowed || isRootLocation || fullAccess;

  const routeComponent = () => {
    if (!user?.id) {
      return (
        <Redirect
          to={{ pathname: locations.loginEmail(), state: { from: location } }}
        />
      );
    }

    const isWizardPage = location.pathname === locations.welcomeWizard();

    if (isProfileIncomplete && !isWizardPage) {
      return <Redirect to={{ pathname: locations.welcomeWizard() }} />;
    }

    return (
      <div
        className={classNames({
          [styles['with-navbar']]: !isWizardPage,
          [styles['with-subnavbar']]: displaySubNavBar,
        })}
      >
        {!isWizardPage && (
          <>
            <Navbar
              isSearchVisible={!isRootLocation}
              isLogoVisible={true}
              user={user}
            />
            {displaySubNavBar && AuthService.isOktaTokensReady() ? (
              <SubNavBar />
            ) : null}
          </>
        )}
        <Component {...rest} />
      </div>
    );
  };

  return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
