import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import classnames from 'classnames';
import { IProperty } from 'interfaces/IProperty';
import { ISearchResult } from 'interfaces/ISearchResult';
import { GET_RECOMMENDATIONS_QUERY } from 'graphql/property';
import PropertyCardList from 'components/PropertyCardList';
import SpinnerLoader from 'components/SpinnerLoader';
import Button from 'components/Button/new';
import { ColorNames } from 'constants/colorNames';
import { ErrorLogger } from 'services/ErrorLogger';
import styles from './RecommendedProperties.module.scss';

import {
  I18N_AVANT_PROPERTY_ATTR_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

const UI_RECOMMENDATIONS_PER_PAGE = 3;
const API_RECOMMENDATIONS_PER_PAGE = 15;

interface Props {
  alreadyExistingPropertyIds: (number | undefined)[];
  isDisabled?: boolean;
  onAddRecommendedProperty: (property: IProperty) => void;
  propertyIdForRecommendations: number | undefined;
  removedProperty: IProperty | null;
}

const RecommendedProperties: React.FC<Props> = ({
  alreadyExistingPropertyIds,
  isDisabled,
  onAddRecommendedProperty,
  propertyIdForRecommendations,
  removedProperty,
}) => {
  const client = useApolloClient();
  const { t } = useTranslation();

  const [loadedInitialSuggestions, setLoadedInitialSuggestions] = useState<
    boolean
  >(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [propertiesPool, setPropertiesPool] = useState<IProperty[]>([]);
  const [apiPage, setApiPage] = useState(1);
  const [uiPage, setUiPage] = useState(1);
  const [propertyId, setPropertyId] = useState<number | undefined>(
    propertyIdForRecommendations,
  );

  const getRecommendedProperties = (page: number = uiPage) => {
    const start = UI_RECOMMENDATIONS_PER_PAGE * (page - 1);
    const end = start + UI_RECOMMENDATIONS_PER_PAGE;

    return propertiesPool.slice(start, end);
  };

  const nextPageIsEmpty = !getRecommendedProperties(uiPage + 1).length;

  const hasEmptyRecommendations =
    uiPage === 1 &&
    !propertiesPool.length &&
    !getRecommendedProperties().length;

  const goToNextPage = () => {
    if (isLoading || nextPageIsEmpty) return;
    setUiPage(cur => cur + 1);
  };

  const goToPrevPage = () => {
    if (uiPage <= 1) return;
    setUiPage(cur => cur - 1);
  };

  const addRecommendedProperty = (property: IProperty) => {
    // go to the prev page if there won't be items in the current page after adding the recommendation
    const countAfterAdd = getRecommendedProperties().length - 1;
    if (countAfterAdd <= 0) {
      goToPrevPage();
    }

    onAddRecommendedProperty(property);
  };

  const renderSpinner = () => {
    return (
      <div className={styles['loader-container']}>
        <SpinnerLoader background={ColorNames.ayTotalBlackColor} />
        <span className={styles['loader-label']}>
          {t<string>(
            `${I18N_AVANT_PROPERTY_ATTR_PATH}.compSet.messages.loadingProperties`,
          )}
        </span>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div
        className={classnames(styles.container, {
          [styles.disabled]: isDisabled,
        })}
        data-testid="recommended-properties-content"
      >
        <header className={styles.header}>
          <div>
            <p className={styles.title}>
              {`${t(
                'avantPlatform.attributes.compSet.label.recommendedProperties',
              )}:`}
            </p>
            <p className={styles.subtitle}>
              {`${t(
                'avantPlatform.attributes.compSet.messages.suggestedResult',
              )}`}
            </p>
          </div>
          <div className={styles['pagination-actions']}>
            <Button
              wrapperClassName={classnames(
                styles['pagination-button'],
                styles.active,
              )}
              onClick={goToPrevPage}
              aria-label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.previousPage`)}
              disabled={isDisabled || uiPage <= 1}
              icon={'arrowLeft'}
              type={'contextual-light'}
            />
            <Button
              wrapperClassName={classnames(
                styles['pagination-button'],
                styles.active,
              )}
              onClick={goToNextPage}
              aria-label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.nextPage`)}
              disabled={isDisabled || isLoading || nextPageIsEmpty}
              icon={'arrowRight'}
              type={'contextual-light'}
            />
          </div>
        </header>
        <PropertyCardList
          isDisabled={isDisabled}
          itemsTopRightButtonClassName={styles['button-add-property']}
          noBorder
          onItemTopRightButtonClick={addRecommendedProperty}
          properties={getRecommendedProperties()}
        />
      </div>
    );
  };

  useEffect(() => {
    const loadRecommendations = async () => {
      setIsLoading(true);

      try {
        const { data } = await client.query<ISearchResult<IProperty>>({
          query: GET_RECOMMENDATIONS_QUERY,
          variables: {
            search: {
              page: {
                page: apiPage,
                size: API_RECOMMENDATIONS_PER_PAGE,
              },
              filter: {
                competitiveSetPropertyId: propertyId,
                competitiveSetIncludeProperty: false,
              },
            },
          },
        });

        const results = data?.properties?.results || [];

        if (results.length) {
          // merge results in the pool, without creating duplicates
          setPropertiesPool(value => {
            const ids = [
              ...value.map(p => p.id),
              ...alreadyExistingPropertyIds,
            ];
            const newProperties = results.filter(p => !ids.includes(p.id));
            return [...value, ...newProperties];
          });
        }
      } catch (e) {
        ErrorLogger.log(e as any, 'Unable to load the recommended properties');
      } finally {
        setIsLoading(false);
        setLoadedInitialSuggestions(true);
      }
    };

    const loadTimeout = setTimeout(loadRecommendations);

    return () => clearTimeout(loadTimeout);
  }, [propertyId, client, apiPage, alreadyExistingPropertyIds]);

  useEffect(() => {
    if (!loadedInitialSuggestions) return;

    // load more results as the user moves ahead with pagination
    const poolLength = propertiesPool.length;
    const limit = uiPage * UI_RECOMMENDATIONS_PER_PAGE;

    if (poolLength && poolLength <= limit) {
      setApiPage(value => value + 1);
    }
  }, [propertiesPool, uiPage, loadedInitialSuggestions]);

  useEffect(() => {
    if (propertyId !== propertyIdForRecommendations) {
      setPropertyId(propertyIdForRecommendations);
    }

    // eslint-disable-next-line
  }, [propertyIdForRecommendations]);

  useEffect(() => {
    // remove recommendations that are already present in the user's list
    setPropertiesPool(pool => {
      return pool.filter(
        property => !alreadyExistingPropertyIds.includes(property.id!),
      );
    });
  }, [alreadyExistingPropertyIds]);

  useEffect(() => {
    if (removedProperty) {
      setPropertiesPool(pool => [...pool, removedProperty!]);
    }
  }, [removedProperty]);

  if (hasEmptyRecommendations && !isLoading) {
    return null;
  }

  return isLoading ? renderSpinner() : renderContent();
};

export default RecommendedProperties;
