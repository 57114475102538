import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import FormControl from 'components/FormControl';
import RadioInput from 'components/Inputs/RadioInput';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import Checkbox from 'components/Checkbox';
import { EscalationIncrement, EscalationType } from 'constants/leases';
import styles from 'components/CreateComps/FormSection/FormSection.module.scss';
import {
  getEscalationFrequencyOptions,
  getEscalationTypeOptions,
} from 'components/CreateComps/FormSection/LeaseForm/staticData';
import { getEscalationSuggestionsCurrency } from 'components/CreateComps/FormSection/LeaseForm/suggestions';
import { parseEnumGraphQL } from 'utils/formatters/string';
import { REGEX_DECIMALS } from 'constants/regex';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import {
  generateRadioInputsLabels,
  getEscalationSuggestionsValue,
  getInputValue,
  isEscalationFlat,
  isEscalationTypeIndexOmr,
  isEscalationTypePercentage,
} from './utils';
import { CapCollarFields } from './CapCollarFields';

interface Props {
  isPropertyUk: boolean;
  currencySymbol: string | null;
  escalation: string | null;
  escalationType: string | null;
  escalationIncrement: string | null;
  escalationCap?: number;
  escalationCollar?: number;
  upwardOnly?: boolean;
  multipleBasis?: boolean;
  onUpdateHandler: (field: string, value: string | boolean) => void;
}
const EscalationFields = ({
  isPropertyUk,
  escalation,
  escalationType,
  escalationCap,
  escalationCollar,
  currencySymbol,
  escalationIncrement,
  upwardOnly,
  multipleBasis,
  onUpdateHandler,
}: Props) => {
  const { t } = useTranslation();

  const updateEscalationIncrement = (value: string) => {
    if (value?.toLowerCase() === EscalationIncrement.FLAT.toLowerCase()) {
      onUpdateHandler('escalationType', '');
      onUpdateHandler('escalation', '');
    }
    onUpdateHandler('escalationIncrement', value);
  };

  const displayCapCollarUK =
    isPropertyUk && escalationType === EscalationType.IndexLinked;

  const updateEscalation = (value: string) =>
    onUpdateHandler('escalation', value);

  const updateEscalationType = (value: string) => {
    const currentTypeIsIndexLinked =
      escalationType?.toLowerCase() === EscalationType.IndexLinked;

    const newValueIsIndexLinked =
      value?.toLowerCase() === EscalationType.IndexLinked;

    if (newValueIsIndexLinked || currentTypeIsIndexLinked) {
      onUpdateHandler('escalation', '');
    }
    onUpdateHandler('escalationType', value ?? null);
  };

  return (
    <>
      <FormControl
        label={translateText(
          'avantProperties.labels.common.escalationFrequency',
        )}
        wrapperClassName={styles['escalation-frequency-list']}
        isMultiline
      >
        <ChoiceInput
          selectedItem={{
            id: parseEnumGraphQL(EscalationIncrement, escalationIncrement),
            name: escalationIncrement,
          }}
          data={getEscalationFrequencyOptions(t)}
          containerWrapperClassName={styles['radio-input-container-multiline']}
          onChange={item => updateEscalationIncrement(item?.id)}
        />
      </FormControl>

      <FormControl
        label={translateText('avantProperties.labels.common.escalation')}
        wrapperClassName={styles['form-row']}
        columnClassName={styles['escalation-cap-container']}
      >
        <div className={styles['wrapper-radio-container']}>
          <ChoiceInput
            selectedItem={{
              id: escalationType,
              name: escalationType,
            }}
            isDisabled={isEscalationFlat(escalationIncrement)}
            data={getEscalationTypeOptions(currencySymbol, isPropertyUk)}
            containerWrapperClassName={styles['radio-input-container']}
            itemWrapperClassName={styles['button-input-item']}
            onChange={item => updateEscalationType(item?.id)}
          />
          {!displayCapCollarUK && (
            <CurrencyInput
              currencySymbol={
                !isEscalationTypePercentage(escalationType)
                  ? currencySymbol
                  : null
              }
              id="lease-escalation"
              iconPosition={
                isEscalationTypePercentage(escalationType) ? 'right' : 'left'
              }
              pattern={REGEX_DECIMALS}
              value={getInputValue(escalation, escalationType)}
              wrapperClassName={classNames({
                [styles['percentage-input']]: isEscalationTypePercentage(
                  escalationType,
                ),
                [styles['with-padding']]: !isEscalationTypePercentage(
                  escalationType,
                ),
              })}
              placeholder={translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.enter`,
              )}
              isDisabled={
                isEscalationFlat(escalationIncrement) ||
                isEscalationTypeIndexOmr(escalationType)
              }
              onChange={value => updateEscalation(value)}
            />
          )}

          {!isEscalationTypeIndexOmr(escalationType) && (
            <RadioInput
              selectedItem={
                (escalation && getEscalationSuggestionsValue(escalation)[0]) ||
                escalation
              }
              data={
                escalationType === EscalationType.Currency
                  ? getEscalationSuggestionsCurrency(currencySymbol)
                  : generateRadioInputsLabels()
              }
              isAllDisabled={
                isEscalationFlat(escalationIncrement) ||
                isEscalationTypeIndexOmr(escalationType)
              }
              onChange={item =>
                !!item.value && updateEscalation(String(item.value))
              }
            />
          )}
        </div>

        {displayCapCollarUK && (
          <CapCollarFields
            escalationCap={escalationCap}
            escalationCollar={escalationCollar}
            onChange={onUpdateHandler}
          />
        )}
      </FormControl>
      {isPropertyUk && (
        <div className={styles['escalation-checkboxes']}>
          <Checkbox
            label={translateText('avantProperties.labels.common.upwardOnly')}
            onChange={checked => onUpdateHandler('upwardOnly', checked)}
            selected={!!upwardOnly}
            labelClassName={styles['checkbox-label']}
          />
          <Checkbox
            label={translateText('avantProperties.labels.common.multipleBasis')}
            onChange={checked => onUpdateHandler('multipleBasis', checked)}
            selected={!!multipleBasis}
            labelClassName={styles['checkbox-label']}
          />
        </div>
      )}
    </>
  );
};

export default EscalationFields;
