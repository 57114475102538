import {
  LeaseTransactionType,
  LeaseFinancialStatusType,
  EscalationType,
  LeaseConfidentialType,
  LeaseRecordType,
  LeaseBaseRentTime,
} from 'constants/leases';
import { IdName } from 'interfaces/IdName';
import { upperFirstLetter } from 'utils/formatters/string';
import { TranslateFunction, translateText } from 'utils/i18n';
import { EscalationIncrement } from 'constants/leases';
import {
  I18N_AVANT_PROPERTY_ENUMERATORS,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

const I18N_TRANSACTION_ENUM_PATH = `${I18N_AVANT_PROPERTY_ENUMERATORS}.transactionTypes`;
const I18N_FINANCIAL_ENUM_PATH = `${I18N_AVANT_PROPERTY_ENUMERATORS}.financialStatusTypes`;
const I18N_ESCALATION_ENUM_PATH = `${I18N_AVANT_PROPERTY_ENUMERATORS}.escalationIncrementTypes`;

export const getBaseRentOptions = (t: TranslateFunction): IdName[] => [
  {
    id: LeaseBaseRentTime.Monthly,
    name: t(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.${LeaseBaseRentTime.Monthly.toLowerCase()}`,
    ),
  },
  {
    id: LeaseBaseRentTime.Annual,
    name: t(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.${LeaseBaseRentTime.Annual.toLowerCase()}`,
    ),
  },
];

export const getEscalationFrequencyOptions = (
  t: TranslateFunction,
): IdName[] => [
  {
    id: EscalationIncrement.ANNUAL,
    name: t(
      `${I18N_ESCALATION_ENUM_PATH}.${EscalationIncrement.ANNUAL.toLowerCase()}`,
    ),
  },
  {
    id: EscalationIncrement.EVERY2YRS,
    name: t(`${I18N_ESCALATION_ENUM_PATH}.everyXYears`, { years: 2 }),
  },
  {
    id: EscalationIncrement.EVERY3YRS,
    name: t(`${I18N_ESCALATION_ENUM_PATH}.everyXYears`, { years: 3 }),
  },
  {
    id: EscalationIncrement.EVERY4YRS,
    name: t(`${I18N_ESCALATION_ENUM_PATH}.everyXYears`, { years: 4 }),
  },
  {
    id: EscalationIncrement.EVERY5YRS,
    name: t(`${I18N_ESCALATION_ENUM_PATH}.everyXYears`, { years: 5 }),
  },
  {
    id: EscalationIncrement.EVERY6YRS,
    name: t(`${I18N_ESCALATION_ENUM_PATH}.everyXYears`, { years: 6 }),
  },
  {
    id: EscalationIncrement.EVERY7YRS,
    name: t(`${I18N_ESCALATION_ENUM_PATH}.everyXYears`, { years: 7 }),
  },
  {
    id: EscalationIncrement.EVERY8YRS,
    name: t(`${I18N_ESCALATION_ENUM_PATH}.everyXYears`, { years: 8 }),
  },
  {
    id: EscalationIncrement.EVERY9YRS,
    name: t(`${I18N_ESCALATION_ENUM_PATH}.everyXYears`, { years: 9 }),
  },
  {
    id: EscalationIncrement.EVERY10YRS,
    name: t(`${I18N_ESCALATION_ENUM_PATH}.everyXYears`, { years: 10 }),
  },
  {
    id: EscalationIncrement.FLAT,
    name: t(`${I18N_ESCALATION_ENUM_PATH}.flat`),
  },
  {
    id: EscalationIncrement.STEPPED,
    name: t(`${I18N_ESCALATION_ENUM_PATH}.stepped`),
  },
];

export const getEscalationTypeOptions = (
  currencySymbol: string | null,
  isUkProperty: boolean,
): IdName[] =>
  [
    {
      id: EscalationType.Percentage,
      name: EscalationType.Percentage,
    },
    {
      id: EscalationType.Currency,
      name: currencySymbol,
    },
    {
      id: EscalationType.IndexLinked,
      name: upperFirstLetter(EscalationType.IndexLinked),
    },

    isUkProperty && {
      id: EscalationType.OpenMarketRent,
      name: 'OMR',
    },
  ].filter(Boolean) as IdName[];

export const getTransactionTypes = (t: TranslateFunction): IdName[] => [
  {
    id: LeaseTransactionType.NEW,
    name: t(
      `${I18N_TRANSACTION_ENUM_PATH}.${LeaseTransactionType.NEW.toLowerCase()}`,
    ),
  },
  {
    id: LeaseTransactionType.RENEWAL,
    name: t(
      `${I18N_TRANSACTION_ENUM_PATH}.${LeaseTransactionType.RENEWAL.toLowerCase()}`,
    ),
  },
  {
    id: LeaseTransactionType.EXPANSION,
    name: t(
      `${I18N_TRANSACTION_ENUM_PATH}.${LeaseTransactionType.EXPANSION.toLowerCase()}`,
    ),
  },
];

export const getFinancialStatusTypes = (t: TranslateFunction): IdName[] => [
  {
    id: LeaseFinancialStatusType.CONFIRMED,
    name: t(
      `${I18N_FINANCIAL_ENUM_PATH}.${LeaseFinancialStatusType.CONFIRMED.toLowerCase()}`,
    ),
  },
  {
    id: LeaseFinancialStatusType.ESTIMATED,
    name: t(
      `${I18N_FINANCIAL_ENUM_PATH}.${LeaseFinancialStatusType.ESTIMATED.toLowerCase()}`,
    ),
  },
];

export const RECORD_TYPE_OPTIONS: IdName[] = [
  {
    id: LeaseRecordType.LEASE_COMPS,
    name: translateText('avantProperties.filters.lease.label.leaseComps'),
  },
  {
    id: LeaseRecordType.LEASE_EXPIRATIONS,
    name: translateText(
      'avantProperties.options.recordType.label.hasExpirationDate',
    ),
  },
  {
    id: LeaseRecordType.ALL_TENANTS,
    name: translateText('avantProperties.filters.lease.label.allTenants'),
  },
];

export const getConfidentialLeaseOptions = (t: TranslateFunction): IdName[] => [
  {
    id: LeaseConfidentialType.no,
    name: t(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.${LeaseConfidentialType.no.toLowerCase()}`,
    ),
  },
  {
    id: LeaseConfidentialType.yes,
    name: t(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.${LeaseConfidentialType.yes.toLowerCase()}`,
    ),
  },
];
