import { gql } from '@apollo/client';

export const CREATE_EXPORT = gql`
  mutation createExport(
    $name: String!
    $type: String!
    $subType: String
    $filters: String!
    $order: String!
    $createUpdate: Boolean
    $userId: Int!
    $shouldReturnPricingQuotes: Boolean
  ) {
    createExport(
      name: $name
      type: $type
      subType: $subType
      filters: $filters
      order: $order
      createUpdate: $createUpdate
      userId: $userId
      shouldReturnPricingQuotes: $shouldReturnPricingQuotes
    ) {
      id
    }
  }
`;
