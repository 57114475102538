import React from 'react';
import classnames from 'classnames';
import classNames from 'classnames';
import locations from '../../routes';
import styles from './Card.module.scss';
import { IProperty } from 'interfaces/IProperty';
import { IdName } from 'interfaces/IdName';
import { ColorNames } from 'constants/colorNames';
import { ICompany } from 'interfaces/ICompany';
import { getCoverImageUrl } from 'components/ModalMedia/utils';
import TagsList from 'components/TagsList';
import { DOT, DOT_LG } from 'constants/placeholders';
import CardDropdown from 'components/CardDropdown';
import { CompType } from 'constants/compType';
import {
  getPropertyAddress,
  getPropertyMarkets,
} from 'utils/formatters/property';
import { PropertyImageSizes } from 'constants/propertyImageSizes';
import AYLink from 'components/AYLink';
import { PropertyMapSize, ZoomLevel } from 'components/PropertyMap';
import StaticMapImage from 'components/PropertyMap/StaticMapImage';
import AlternateAddressIconSmall from 'components/AlternateAddressIconSmall';

export type Size = 'regular' | 'small' | 'extra-small';

type CardProps = {
  tags?: IdName[];
  headline?: string;
  subheadline?: string;
  bodyLine?: string;
  companiesLinks?: ICompany[];
  property?: IProperty;
  company?: ICompany;
  size?: Size;
  contact?: {
    name: string;
    email: string;
  };
  showPropertyData?: boolean;
  showActionButtons?: boolean;
  buildingMetadata?: string;
  tagBackgroundColor?: ColorNames;
  toggleOnClickCard?: boolean;
  children?: React.ReactNode;
  onDelete?: () => void;
  onEdit?: () => void;
  comp?: {
    id?: number;
    type: CompType;
  };
  onUpdate?: () => void;
  wrapperClassName?: string;
};

const Card: React.FC<CardProps> = (props: CardProps) => {
  const {
    company,
    property,
    tags,
    headline,
    subheadline,
    bodyLine,
    contact,
    companiesLinks,
    size,
    showPropertyData,
    showActionButtons,
    buildingMetadata,
    tagBackgroundColor,
    children,
    onDelete,
    onEdit,
    comp,
    onUpdate,
    wrapperClassName,
  } = props;
  const name = property?.name;
  const tagBackgroundClass =
    styles[`background-${tagBackgroundColor || ColorNames.ayFrozenGrassColor}`];

  const renderHeaderWithProperty = () => {
    const propertyImage = getCoverImageUrl(property, PropertyImageSizes.m);
    const propertyMarkets = getPropertyMarkets(property, DOT_LG);

    const renderPropertyThumb = () => {
      if (!property) return null;

      return !!propertyImage ? (
        <div>
          <img alt={name} src={propertyImage} className={styles.image} />
        </div>
      ) : (
        <div>
          <StaticMapImage
            wrapperClassName={styles.map}
            size={PropertyMapSize.extraSmall}
            zoomLevel={ZoomLevel.big}
            property={property!}
          />
        </div>
      );
    };

    return (
      <div
        className={classnames(
          styles['primary-address'],
          styles['header-with-property'],
        )}
      >
        <div className={styles['header-container']}>
          {renderPropertyThumb()}
          <AYLink
            to={locations.showProperty(property?.id!)}
            wrapperClassName={styles['content-title']}
          >
            <div className={styles['title']}>
              {getPropertyAddress(property)}
              {!!property?.numberOfAlternativeAddresses &&
                property?.numberOfAlternativeAddresses > 0 && (
                  <div className={styles['title-icon']}>
                    <AlternateAddressIconSmall property={property} />
                  </div>
                )}
            </div>
            {!!name && <p className={styles['subtitle']}>{name}</p>}
            {!!propertyMarkets && (
              <p className={styles['subtitle']}>{propertyMarkets}</p>
            )}
          </AYLink>
        </div>
        {showActionButtons && (
          <div className={styles['action-buttons']}>
            <button
              className={classNames(
                styles['delete-button'],
                styles['act-button'],
              )}
              type="button"
              onClick={onDelete}
            />
            <button
              className={classNames(
                styles['edit-button'],
                styles['act-button'],
              )}
              type="button"
              onClick={onEdit}
            />
          </div>
        )}
      </div>
    );
  };

  const renderCompaniesLinks = () => {
    if (!companiesLinks?.length) return null;

    return (
      <div>
        {companiesLinks.map((c: ICompany, index: number) => (
          <div key={c.id} className={styles['company-name']}>
            {c.id ? (
              <AYLink
                wrapperClassName={styles['body-line']}
                to={locations.showCompany(c.id!)}
              >
                {c.name}
                {index < companiesLinks.length - 1 && <span> {DOT}</span>}
              </AYLink>
            ) : (
              <div className={styles['body-line']}>
                {c.name}
                {index < companiesLinks.length - 1 && <span> {DOT}</span>}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const metadata = [];
  if (buildingMetadata) {
    metadata.push(buildingMetadata);
    if (contact) {
      metadata.push(
        ' · ',
        <a
          key={contact.name}
          className={styles['link']}
          href={`mailto:${contact.email}`}
        >
          {contact.name}
        </a>,
      );
    }
  }

  return (
    <div
      className={classnames(styles['card'], wrapperClassName, {
        [styles[`${size}`]]: size,
      })}
      data-testid="card"
    >
      {comp?.id && (
        <CardDropdown
          type={comp.type}
          compId={comp.id}
          onUpdate={onUpdate}
          onDelete={onDelete}
          wrapperClassName={styles['dropdown']}
          isDark
        />
      )}

      {showPropertyData && (
        <header className={styles['header-wrapper']}>
          {renderHeaderWithProperty()}
        </header>
      )}

      {headline && (
        <div className={styles['headline']}>
          {headline}
          {subheadline && (
            <span className={styles['subheadline']}>{subheadline}</span>
          )}
        </div>
      )}

      <div className={styles['metadata']}>{metadata}</div>
      {bodyLine &&
        (company?.id ? (
          <AYLink
            wrapperClassName={styles['body-line']}
            to={locations.showCompany(company.id!)}
          >
            {bodyLine}
          </AYLink>
        ) : (
          <div className={styles['body-line']}>{bodyLine}</div>
        ))}
      {renderCompaniesLinks()}
      {tags && (
        <TagsList
          tags={tags}
          tagBackgroundClass={tagBackgroundClass}
          isExtraSmall={size === 'extra-small'}
        />
      )}
      {children}
    </div>
  );
};

export default Card;
