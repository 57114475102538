import { colors } from 'constants/colors';
import { PROFILE_TYPES } from 'constants/profileTypes';
import { SwitchOption } from 'components/SwitchOptionButton';
import IHistoricalPerformance from 'interfaces/IHistoricalPerformance';
import {
  IAssetPerformanceCompany,
  IAssetPerformanceProperty,
} from 'interfaces/IHistoricalPerformance';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_LABEL_PATH,
  I18N_AVANT_PROPERTY_TEXT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { IndexedProfileType } from 'interfaces/IndexedProfileType';

export const I18N_HISTORICAL_PERFORMANCE_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.historicalPerformance`;
export const I18N_HISTORICAL_PERFORMANCE_GRAPH_LABEL_PATH = `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.graphs`;
export const I18N_HISTORICAL_PERFORMANCE_TEXT_PATH = `${I18N_AVANT_PROPERTY_TEXT_PATH}.historicalPerformance`;

export const I18N_HISTORICAL_AVAILABILITY_LABEL_PATH = `${I18N_HISTORICAL_PERFORMANCE_GRAPH_LABEL_PATH}.historicalAvailability`;

export const I18N_CURRENT_PERFORMANCE_LABEL_PATH = `${I18N_HISTORICAL_PERFORMANCE_GRAPH_LABEL_PATH}.currentPerformance`;

export type BuildingHistoricalRentItem = {
  year: number;
  buildingValue: number;
  buildingValueMonthly: number;
  buildingTI: number;
  buildingTIMonthly: number;
  buildingFreeRentMonths: number;
  compSetValue: number;
  compSetValueMonthly: number;
  compSetTI: number;
  compSetTIMonthly: number;
  compSetFreeRentMonths: number;
  timeMeasurement: string;
};

export type BuildingHistoricalRent = {
  rentTypeFS: BuildingHistoricalRentItem[];
  rentTypeNNN: BuildingHistoricalRentItem[];
};

export type CompanyHistoricalRentItem = {
  year: number;
  rentAvg: number;
  rentAvgMonthly: number;
  compSetTI: number;
  compSetTIMonthly: number;
  compSetFreeRentMonths: number;
  timeMeasurement: string;
};

export type CompanyHistoricalRent = {
  rentTypeFS: CompanyHistoricalRentItem[];
  rentTypeNNN: CompanyHistoricalRentItem[];
};

export type SubmarketHistoricalRentItem = {
  year: number;
  compSetValue: number;
  compSetValueMonthly: number;
  compSetTI: number;
  compSetTIMonthly: number;
  compSetFreeRentMonths: number;
  timeMeasurement: string;
};

export type SubmarketHistoricalRent = {
  rentTypeFS: SubmarketHistoricalRentItem[];
  rentTypeNNN: SubmarketHistoricalRentItem[];
};

export type HistoricalAvailabilityDataType = {
  data: { graphAssetPerformanceTrend: IHistoricalPerformance[] };
  loading: boolean;
};

export type CurrentPerformanceDataType = {
  data: {
    graphAssetPerformanceCurrentProperty?: IAssetPerformanceProperty;
    graphAssetPerformanceCurrentCompany?: IAssetPerformanceCompany;
  };
  loading: boolean;
};

export type BuildingHistoricalRentResponse = {
  data: { buildingHistoricalRent: BuildingHistoricalRent };
  loading: boolean;
};

export type CompanyHistoricalRentResponse = {
  data: { graphCompanyHistoricalRent: CompanyHistoricalRent };
  loading: boolean;
};

export type SubmarketHistoricalRentResponse = {
  data: { submarketHistoricalRent: SubmarketHistoricalRent };
  loading: boolean;
};

export type GraphsDataType = {
  historicalPerformance: HistoricalAvailabilityDataType;
  currentPerformance: CurrentPerformanceDataType;
  buildingHistoricalRent: BuildingHistoricalRentResponse;
  companyHistoricalRent: CompanyHistoricalRentResponse;
  submarketHistoricalRent?: SubmarketHistoricalRentResponse;
};

export type MeasurementParams = {
  totalSize: number;
  currencySymbol?: string;
  unitOfMeasurement: string;
};

export enum HistoricalTypes {
  OCCUPIED = 'occupied',
  AVAILABLE = 'available',
  VACANT = 'vacant',
}

export const donutGraphColors: IndexedProfileType = {
  [PROFILE_TYPES.PROPERTY]: {
    firstSlice: colors.supportive500,
    secondSlice: colors.primaryColor500,
    secondary: colors.ayPureWhiteColor,
    tertiary: colors.supportive500,
  },
  [PROFILE_TYPES.COMPANY]: {
    firstSlice: colors.supportive500,
    secondSlice: colors.primaryColor900,
    secondary: colors.ayPureWhiteColor,
    tertiary: colors.supportive500,
  },
  [PROFILE_TYPES.SUBMARKET]: {
    firstSlice: colors.primaryColor500,
    secondSlice: colors.primaryColor500,
    secondary: colors.ayPureWhiteColor,
    tertiary: colors.primaryColor500,
  },
  [PROFILE_TYPES.MARKET]: {
    firstSlice: colors.primaryColor500,
    secondSlice: colors.primaryColor500,
    secondary: colors.ayPureWhiteColor,
    tertiary: colors.primaryColor500,
  },
};

export const generateHistoricalTypeButtonOption = (
  type: HistoricalTypes,
  translatePath: string,
): SwitchOption => ({
  value: type,
  text: translateText(translatePath),
});

const { OCCUPIED, AVAILABLE, VACANT } = HistoricalTypes;

export const historicalTypeParam = {
  [OCCUPIED]: generateHistoricalTypeButtonOption(
    OCCUPIED,
    `${I18N_PLATFORM_COMMON_WORD_PATH}.${OCCUPIED}`,
  ),
  [VACANT]: generateHistoricalTypeButtonOption(
    VACANT,
    `${I18N_PLATFORM_COMMON_WORD_PATH}.${VACANT}`,
  ),
  [AVAILABLE]: generateHistoricalTypeButtonOption(
    AVAILABLE,
    `${I18N_PLATFORM_COMMON_WORD_PATH}.${AVAILABLE}`,
  ),
};
